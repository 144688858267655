import { mapActions, mapGetters } from "vuex";
import * as types from "./../../store/types";
import $statusDictionary from "./../../mixins/statusDictionary";
import $formatNumber from "./../../mixins/formatNumber";
import $renderDate from "./../../mixins/renderDate";
import QrcodeVue from "qrcode.vue";
import { ASSETS_URL } from "./../../services/base";
import transactionsService from "../../services/transactionsService";

export default {
    name: "order-list",
    components: {
        QrcodeVue,
    },
    props: ["data", "headers"],
    mixins: [$statusDictionary, $formatNumber, $renderDate],
    data() {
        return {
            baseURL: ASSETS_URL,
            types: "",
            statuses: "",
            countdownTimer: "",
            clockInterval: "",
            isExpired: false,
            isProcessed: false,
            loading: false,
            size: 300,
        };
    },
    computed: {
        ...mapGetters({
            currencies: types.GET_CURRENCIES,
            userInfo: types.GET_USER_INFO,
            orderDetails: types.GET_ORDER_DETAILS,
        }),
    },
    mounted() {
        this.getStatusFunc();
    },
    methods: {
        ...mapActions({
            orderDetailsAction: types.ACTION_ORDER_DETAILS,
        }),
        getStatusFunc() {
            transactionsService.getMerchantOrdersStatus().then((response) => {
                this.statuses = response.data;
            });
        },
        getTypesFunc() {
            transactionsService.getMerchantOrdersTypes().then((response) => {
                this.types = response.data;
            });
        },
        continueTransaction(item) {
            if (item.orderType === "CASHIER") {
                window.open(
                    "/" +
                        `${this.$i18n.locale}` +
                        "/" +
                        `${this.userInfo.merchants[0].slug}` +
                        "?token=" +
                        item.token +
                        "&mode=" +
                        "off" +
                        "&cashier=" +
                        true +
                        "&coin=" +
                        item.coin
                );
            }
            if (item.orderType === "ATM") {
                window.open(
                    "/" +
                        `${this.$i18n.locale}` +
                        "/" +
                        `${this.userInfo.merchants[0].slug}` +
                        "?token=" +
                        item.token +
                        "&mode=" +
                        "off" +
                        "&merchantId=" +
                        localStorage.getItem("bitrahAccessToken") +
                        "&coin=" +
                        item.coin
                );
            }
        },
    },
};
